import { React, Component } from "react";
import '../CmsApi.js';

class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="min-h-screen relative flex flex-col min-w-0 w-full border-0 align-middle">
                <div className="">
                    <h1 className="text-gray-700 font-semibold text-xl text-center">
                        Política de privacidad
                    </h1>
                    <div className="text-gray-600 text-sm max-w-lg mx-auto text-justify">
                        <p>Esta política de tratamiento y privacidad establece la forma en que la aplicación SanityControl (Prevent) recoge, almacena, da tratamiento, maneja, administra, transfiere, transmite y/o comparte la información, sea de naturaleza personal o no, que Usted suministra o provee cuando ingresa y se registra en la Aplicación o en cualquier otro canal que se desarrolle en el futuro por SanityControl, o de cualquier forma utiliza la Aplicación y/o los demás servicios que se ofrecen en SanityControl.</p>

                        <p>La información que sobre el Usuario se recoge, almacena, maneja, administra, transfiere, transmite y/o comparte SanityControl, puede o no ser de naturaleza personal, puede o no ser de carácter privado, e incluso puede ser información o datos protegidos por las leyes aplicables sobre protección de datos personales. Cualquiera sea la naturaleza de la información, su tratamiento, manejo, almacenamiento, transferencia, transmisión y/o administración por parte de SanityControl, respetará las normas aplicables sobre protección de datos personales.</p>

                        <p>El Usuario, como propietario o titular de la Información Personal, o como sujeto al que hace referencia dicha información, tendrá los derechos que las respectivas legislaciones aplicables les otorguen, en las correspondientes jurisdicciones donde se opere. En Colombia, los Usuarios (titulares de la Información Personal), tendrán los derechos establecidos en la Ley 1581 de 2012, y en la Ley 1266 de 2008, y en cualquier otra norma que las modifique, sustituya o complemente, en particular, pero sin limitarse al Decreto 1377 de 2013, como los derechos al acceso, revocación, supresión, actualización, rectificación, corrección, cancelación y oposición de sus datos personales e Información Personal.</p>

                        <p>Se entiende por "Datos Sensibles" aquellos que afectan la intimidad del Usuario o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos.</p>

                        <p>En SanityControl se requiere del nombre del usuario y se permite el registro de datos relacionados con el control sanitario y de seguridad y salud en el trabajo en las empresas del sector alimentario donde se realizan las actividades de gestión sanitaria, esta información se almacena, protege y respalda en servidores seguros usando encriptación en el transporte y almacenamiento de datos sensibles como la contraseña de ingreso.</p>

                        <p>SanityControl se compromete al cumplimiento de su obligación de secreto de los datos de carácter personal que sean Información Privada (es decir que no tengan la naturaleza de pública), y de su deber de tratarlos con confidencialidad, y asume, a estos efectos, las medidas de índole técnica, organizativa y de seguridad necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado. La Información Personal de la cuenta de SanityControl de cada Usuario está protegida por una contraseña para su privacidad y seguridad. El Usuario debe evitar el acceso no autorizado a su cuenta e Información Personal seleccionando y protegiendo la contraseña de forma adecuada y limitando el acceso a su dispositivo móvil cerrando la sesión al finalizar el acceso a su cuenta.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Privacy;