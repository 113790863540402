import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Header from './parts/header';
import Main from './parts/main';
import Footer from './parts/footer';
import Page from './screens/page'
import Privacy from './screens/privacy'

function App() {
  return (
    <BrowserRouter>
      <>
        <Header></Header>
        <Routes>
          <Route path="/privacy" element={
            <div className="flex flex-wrap bg-white">
              <div className="container">
                <Privacy />
              </div>
            </div>
          }></Route>
          <Route path="/page/*" element={
            <div className="flex flex-wrap bg-white">
              <div className="container">
                <Page />
              </div>
            </div>
          }></Route>
          <Route path="*" element={
            <Main></Main>
          }></Route>
        </Routes>

        <Footer></Footer>
      </>
    </BrowserRouter>
  );
}

export default App;
